var lazyLoadInit = function(rootConfig, lazyClass) {
  lazyClass = lazyClass || 'lazy';
  var lazyloadImages, i;

  if ("IntersectionObserver" in window) {
    lazyloadImages = document.querySelectorAll("."+lazyClass);
    var imageObserver = new IntersectionObserver(function(entries, observer) {
      var entry,
          onImageLoad = function() {
            this.classList.remove(lazyClass);
          };
      for (i = 0; i < entries.length; i++) {
        entry = entries[i];
        if (entry.isIntersecting) {
          var image = entry.target;
          if (image.getAttribute('data-srcset')) {
            image.srcset = image.getAttribute('data-srcset');
          }
          image.src = image.getAttribute('data-src');
          image.onload = onImageLoad;
          imageObserver.unobserve(image);
        }
      }
    }, rootConfig);

    for (i = 0; i < lazyloadImages.length; i++) {
      imageObserver.observe(lazyloadImages[i]);
    }
  } else {
    var timeoutHandler,
       lazyloadFallback = function() {
          rootConfig = rootConfig || {}; 
          var rootQuery = rootConfig.root || document,
              rootMargin = rootConfig.rootMargin ? parseInt(rootConfig.rootMargin) : 0;
          lazyloadImages = rootQuery.querySelectorAll("."+lazyClass);
          if (timeoutHandler) {
            //clearTimeout(timeoutHandler);
          }

          timeoutHandler = setTimeout(function() {
            var scrollTop = window.pageYOffset,
                scrollLeft = window.pageXOffset,
                img, imgPos,
                rootWidth = rootConfig.root ? rootConfig.root.clientWidth : window.innerWidth,
                rootHeight = rootConfig.root ? rootConfig.root.clientHeight : window.innerHeight,
                onImageLoad = function() {
                    this.classList.remove(lazyClass);
                };
            for (var i = 0; i < lazyloadImages.length; i++) {
                img = lazyloadImages[i];
                imgPos = img.getBoundingClientRect();
                if (imgPos.top - rootMargin < rootHeight && imgPos.left - rootMargin < rootWidth) {
                  if (img.getAttribute('data-srcset')) {
                    img.srcset = img.getAttribute('data-srcset');
                  }
                  img.src = img.getAttribute('data-src');
                  
                  img.onload = onImageLoad;
                }
            }
            if (lazyloadImages.length === 0) {
              document.removeEventListener("scroll", lazyloadFallback);
              window.removeEventListener("resize", lazyloadFallback);
              window.removeEventListener("orientationChange", lazyloadFallback);
            }
          }, 20);
        };
    lazyloadFallback();

    window.addEventListener("doLazyLoad", lazyloadFallback);
    document.addEventListener("scroll", lazyloadFallback);
    window.addEventListener("resize", lazyloadFallback);
    window.addEventListener("orientationChange", lazyloadFallback);
  }

};

var lazyLoadBackgroundInit = function(rootConfig, lazyClass) {
  lazyClass = lazyClass || 'lazy-background';
  var lazyloadImages, i;
  if ("IntersectionObserver" in window) {
    lazyloadImages = document.querySelectorAll("."+lazyClass);
    var imageObserver = new IntersectionObserver(function(entries, observer) {
      var entry;
      for (i = 0; i < entries.length; i++) {
        entry = entries[i];
        if (entry.isIntersecting) {
          if (entry.target.hasAttribute('data-bg-img')) {
            entry.target.style.backgroundImage = 'url("'+entry.target.getAttribute('data-bg-img')+'")';
          } else {
            entry.target.classList.add("visible");
          }
          imageObserver.unobserve(entry.target);
        }
      }
    }, rootConfig);

    for (i = 0; i < lazyloadImages.length; i++) {
      imageObserver.observe(lazyloadImages[i]);
    }
  } else {
    var timeoutHandler,
        lazyloadFallback = function() {
          rootConfig = rootConfig || {}; 
          var rootQuery = rootConfig.root || document,
              rootDims = rootConfig.root || window,
              rootMargin = rootConfig.rootMargin ? parseInt(rootConfig.rootMargin) : 0;
          lazyloadImages = rootQuery.querySelectorAll("."+lazyClass);
          if (timeoutHandler) {
            clearTimeout(timeoutHandler);
          }

          timeoutHandler = setTimeout(function() {
            var scrollTop = window.pageYOffset,
                scrollLeft = window.pageXOffset,
                img, imgPos,
                rootWidth = rootConfig.root ? rootConfig.root.clientWidth : window.innerWidth,
                rootHeight = rootConfig.root ? rootConfig.root.clientHeight : window.innerHeight;        
            for (var i = 0; i < lazyloadImages.length; i++) {
                img = lazyloadImages[i];
                imgPos = img.getBoundingClientRect();
                if (imgPos.top - rootMargin < rootHeight && imgPos.left - rootMargin < rootWidth) {
                  if (img.hasAttribute('data-bg-img')) {
                    img.style.backgroundImage = 'url("'+img.getAttribute('data-bg-img')+'")';
                  } else {
                    img.classList.add("visible");
                  }
                }
            }
            if (lazyloadImages.length === 0) {
              document.removeEventListener("scroll", lazyloadFallback);
              window.removeEventListener("resize", lazyloadFallback);
              window.removeEventListener("orientationChange", lazyloadFallback);
            }
          }, 20);
        };
    lazyloadFallback();

    document.addEventListener("scroll", lazyloadFallback);
    window.addEventListener("resize", lazyloadFallback);
    window.addEventListener("orientationChange", lazyloadFallback);
  }

};
